<template>
  <v-list-item dense :disabled="disabled" v-on="$listeners">
    <v-list-item-icon v-if="icon">
      <v-icon dense :color="iconColor" v-text="icon" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="checked">
      <v-icon small class="ml-4" color="primary">$check</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'DropdownMenuItemCheck',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
