<template>
  <v-menu v-bind="$attrs" max-height="calc(100vh - 40px)" min-width="170">
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on">
        <v-btn icon @click.stop.prevent="on.click" @mousedown.stop="">
          <v-icon :color="activatorIconColor">{{ activatorIcon }}</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-list>
      <dropdown-menu-item-check
        v-for="item in items"
        :key="item.id"
        v-bind="item"
        :checked="item.id === value"
        @click="$emit('input', item)"
      />
    </v-list>
  </v-menu>
</template>

<script>
import DropdownMenuItemCheck from './DropdownMenuItemCheck';
export default {
  name: 'DropdownMenuSelector',
  components: {
    DropdownMenuItemCheck
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    activatorIcon: {
      type: String,
      default: '$more_vert'
    },
    activatorIconColor: {
      type: String,
      default: 'primary'
    },

    value: {
      type: String,
      default: 'default'
    }
  }
};
</script>
